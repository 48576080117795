// @flow
import { createSlice } from "@reduxjs/toolkit";

const GeneralReducer = createSlice({
  name: "general",
  initialState: {
    media: [],
    models: [],
  },
  reducers: {
    // GET MEDIA STATUS
    getMediaStatusRequest() {},

    // GENERATE UPLOAD URL
    generateUploadURLRequest() {},

    // GENERATE PROMPT UPLOAD URL
    generatePromptUploadURLRequest() {},

    // UPLOAD MEDIA
    uploadMediaRequest() {},

    // DELETE UPLOADED MEDIA
    deleteUploadMediaRequest() {},

    // GET MODELS
    getModelsRequest() {},
    getModelsRequestSuccess(state, { payload }) {
      state.models = payload;
    },
  },
});

export const {
  generateUploadURLRequest,
  generatePromptUploadURLRequest,
  uploadMediaRequest,
  getMediaStatusRequest,
  deleteUploadMediaRequest,
  getModelsRequest,
  getModelsRequestSuccess,
} = GeneralReducer.actions;

export default GeneralReducer.reducer;
