import { MONTHS } from "../constants";
import { getFormattedUnixDateTime } from "../utils";

const manipulateChatMessageData = (data) => {
  const images = data?.chat_assets?.map((item) => ({
    name: item?.file_name,
    data: item?.download_url,
    type:
      item?.type === "vision"
        ? "image"
        : item?.type === "audio"
        ? "audio"
        : "file",
  }));
  let query = {
    id: data?.query_id + "-query",
    queryId: data?.query_id,
    message: data?.query,
    images,
  };
  let response = {
    id: data?.query_id,
    isPrompt: true,
    isCompleted: true,
    isLoading: false,
    model: data?.model ?? "",
    sources: data?.metadata?.sources ?? [],
    message: data?.response,
    feedback: data?.metadata?.feedback ?? {},
  };
  return [query, response];
};

const manipulateChatMessageList = (data) => {
  if (!data) return [];

  const messages = [];
  data?.forEach((item) => messages.unshift(...manipulateChatMessageData(item)));
  return messages;
};

const categorizeChat = (createdAt) => {
  const chatDate = new Date(createdAt);
  const today = new Date();
  const diffInDays = parseInt((today - chatDate) / (1000 * 60 * 60 * 24));
  if (diffInDays === 0) {
    return "Today";
  } else if (diffInDays === 1) {
    return "Yesterday";
  } else if (diffInDays <= 7) {
    return "Past 7 Days";
  } else if (chatDate.getFullYear() === today.getFullYear()) {
    return MONTHS[chatDate.getMonth()];
  } else {
    return chatDate.getFullYear();
  }
};

export const manipulateChatDetail = (data, newChat) => {
  let createdAt = getFormattedUnixDateTime(data?.time_start);
  const chat = {
    id: data?.id ?? data?.session_id,
    name: data?.session_name ?? "",
    messages: manipulateChatMessageList(data?.chat_history),
    category: categorizeChat(createdAt),
    isAnimated: newChat,
    createdAt,
  };
  return chat;
};

export const manipulateChatList = (data) => {
  const chats = data?.map((item) => manipulateChatDetail(item));
  return chats;
};
