import React, { useState, useEffect } from "react";
import { Avatar, Checkbox, Input, Tooltip } from "antd";
import { Button, Dropdown } from "../../../../components";
import { PROJECT_ROLES_OPTIONS } from "../../../../constants";
import { useSelector } from "react-redux";
import { updateProjectUserRequest } from "../../../../redux/slicers/project";
import { CustomDispatch } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const UserDetail = ({ data, isOwner, selectUser, isSelected }) => {
  // STATES
  const [selectedRole, setselectedRole] = useState(PROJECT_ROLES_OPTIONS[0]);

  // REDUX DATA
  const projectData = useSelector(({ project }) => project.selectedProject);
  const users = useSelector(({ project }) => project.projectUsers);

  // CUSTOM DISPATCH
  const [updateRole] = CustomDispatch(updateProjectUserRequest);

  // HELPERS
  const updateRoleHandler = (role) => {
    setselectedRole(role);
    const payload = {
      resource: "access",
      method: "update",
      details: {
        project_id: projectData.id,
        users: [
          {
            user_id: data?.user_id,
            role: role.value,
          },
        ],
      },
    };
    updateRole({
      payload,
    });
  };

  // HOOKS
  useEffect(() => {
    if (!data) return;
    const role = PROJECT_ROLES_OPTIONS.find((x) => x.value === data?.role);
    setselectedRole(role);
  }, [data]);

  return (
    <div className={clsx("user-detail", users.length >= 3 && "overflow")}>
      <span className="select-box">
        {!isOwner && (
          <Checkbox
            checked={isSelected}
            onClick={() => selectUser(data?.user_id)}
          />
        )}
      </span>
      <Avatar className="thumb" src={data?.photo_url}>
        {data?.name?.charAt(0) ?? data?.user_id?.charAt(0)}
      </Avatar>
      <button className="detail" onClick={() => selectUser(data?.user_id)}>
        <h4>{data?.name ?? data?.user_id}</h4>
        <p>{data?.email ?? "-"}</p>
      </button>
      {data?.role === "owner" ? (
        <span className="owner-tag">Owner</span>
      ) : (
        <Dropdown
          className="user-permission"
          selectedVal={selectedRole?.label || "Select Role"}
          selectHandler={(val) => updateRoleHandler(val)}
          options={PROJECT_ROLES_OPTIONS}
          optionBox={({ label }) => label}
        />
      )}
    </div>
  );
};

const SharedList = ({
  selectedUsers,
  setselectedUsers,
  removeUserPreviewHandler,
}) => {
  // REDUX DATA
  const users = useSelector(({ project }) => project.projectUsers);

  // CONST VALS
  // const owners = users?.filter((x) => x.role === "owner");
  const collaborators = users?.filter((x) => x.role !== "owner");

  // STATES
  const [sortDirectionName, setSortDirectionName] = useState(null);
  const [sortDirectionRole, setSortDirectionRole] = useState(null);
  const [sortedUsers, setSortedUsers] = useState(users);
  const [searchQuery, setSearchQuery] = useState("");

  // HANDLERS
  const selectAllUserHandler = () => {
    if (selectedUsers.length === collaborators.length)
      return setselectedUsers([]);
    setselectedUsers(collaborators.map((user) => user.user_id));
  };

  const selectUserHandler = (userid) => {
    if (selectedUsers.includes(userid))
      return setselectedUsers(selectedUsers.filter((x) => x !== userid));
    setselectedUsers([...selectedUsers, userid]);
  };

  const toggleSortDirectionName = () => {
    const newSortDirection = sortDirectionName === "asc" ? "desc" : "asc";
    setSortDirectionName(newSortDirection);
  };

  const toggleSortDirectionRole = () => {
    const newSortDirection = sortDirectionRole === "asc" ? "desc" : "asc";
    setSortDirectionRole(newSortDirection);
  };

  useEffect(() => {
    let filteredUsers = [...users];

  // Filter users by search query (search by user_id, name, email)
  if (searchQuery) {
    filteredUsers = filteredUsers.filter((user) => {
      // Ensure name and email are valid before calling toLowerCase()
      const name = user.name ? user.name.toLowerCase() : '';
      const email = user.email ? user.email.toLowerCase() : '';

      return (
        user.user_id.toString().includes(searchQuery) ||
        name.includes(searchQuery.toLowerCase()) ||
        email.includes(searchQuery.toLowerCase())
      );
    });
  }

    // Filter owners
    const owners = filteredUsers.filter((user) => user.role === "owner");
    let otherUsers = filteredUsers.filter((user) => user.role !== "owner");
  
    // Sort others by name
    if (sortDirectionName) {
      otherUsers.sort((a, b) => {
        // Handle missing name by treating it as an empty string and push to the bottom
        const nameA = a.name ? a.name : ''; // Treat missing names as empty string
        const nameB = b.name ? b.name : ''; // Treat missing names as empty string
        
        return sortDirectionName === "asc"
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    }

    // Sort others by role
    if (sortDirectionRole) {
      otherUsers.sort((a, b) =>
        sortDirectionRole === "asc"
          ? a.role.localeCompare(b.role)
          : b.role.localeCompare(a.role)
      );
    }
  
    setSortedUsers([...owners, ...otherUsers]);
  }, [searchQuery, sortDirectionName, sortDirectionRole, users]);

    //Handle search input change
    const handleSearchChange = (e) => {
      const query = e.target.value;
      setSearchQuery(query);
    };

  return (
    <div className="shared-list customize-form">
      <div className="top-box">
        {collaborators.length > 0 && (
        <>
        <div className="search-bar-container">
          <Input
            prefix={<FontAwesomeIcon className="thumb" icon={faSearch} />}
            type="text"
            placeholder="Search users here..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        {/* Conditionally render the "Remove selected" button */}        
            {selectedUsers.length > 0 && (
              <Button
                invertedTheme
                className="manage-btn"
                boldText
                type="button"
                
              onClick={removeUserPreviewHandler}              
            > Remove selected
            </Button>
            )}
          </>
        )}
      </div>
      
      {/* Check box and Sorting by - name of user, role of user*/}
      <div className="sorting-container">

        {/* Check box to select users */}
        <div className="check-box">
          {collaborators.length > 0 && (
            <>
              <Checkbox
                checked={selectedUsers.length === collaborators.length}
                onChange={selectAllUserHandler}
              > {/*Select all*/}
              </Checkbox>
            </>
          )}
        </div>

        {/* Sorting caret for names */}
        <div
          className="caret-name-container"
          onClick={toggleSortDirectionName}
          tabIndex="0"
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
              toggleSortDirectionName();
            }}}
          > {/* Tooltip for sorting by User */}
          <Tooltip title={sortDirectionName === "asc" ? "Click to Sort Descending" : "Click to Sort Ascending"}>
            <span>
              User <FontAwesomeIcon icon={sortDirectionName === "asc" ? faAngleUp : faAngleDown} />
            </span>
          </Tooltip>
        </div>

        {/* Sorting caret for roles */}
        <div
          className="caret-role-container"
          onClick={toggleSortDirectionRole}
          tabIndex="0"
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
              toggleSortDirectionRole();
            }}}
          > {/* Tooltip for sorting by Role */}
          <Tooltip title={sortDirectionRole === "asc" ? "Click to Sort Descending" : "Click to Sort Ascending"}>
            <span>
              Role <FontAwesomeIcon icon={sortDirectionRole === "asc" ? faAngleUp : faAngleDown} />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className={clsx("users-listing", users.length >= 3 && "scrollable")}>
        {sortedUsers?.map((user, index) => (
          <UserDetail
            data={user}
            key={index}
            isOwner={user.role === "owner"}
            selectUser={selectUserHandler}
            isSelected={selectedUsers.includes(user.user_id

            )}
          />
        ))}
      </div>
    </div>
  );
};

export default SharedList;
