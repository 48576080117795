import ErrorThumbnail from "../assets/images/error-thumb.svg";
import Logo from "../assets/images/logo.png";
import LogoThumb from "../assets/images/logo-thumb.png";
import UserThumb from "../assets/images/user.jpeg";
import ASUBanner from "../assets/images/asu-banner.png";
import AI from "../assets/images/ai.svg";
import UpDownLeftRight from "../assets/images/up-down-left-right.svg";
import Stars from "../assets/images/stars.svg";
import VoiceLoader from "../assets/images/voice-loader.gif";

const Images = {
  ErrorThumbnail,
  Logo,
  LogoThumb,
  UserThumb,
  ASUBanner,
  AI,
  UpDownLeftRight,
  Stars,
  VoiceLoader,
};

export default Images;
